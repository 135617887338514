













import {computed, defineComponent, PropType, Ref} from '@vue/composition-api';
import {useVModel} from '@vueuse/core';
import {AssistedGradingPhrases} from '@/tasks/models/AssistedGradingMap';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'AssistedGradingWritten',
  components: {STextarea},
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    assistedGradingPhrases: {
      type: Array as PropType<AssistedGradingPhrases[]>,
    },
    modelValue: {
      type: String as PropType<string>,
    },
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  setup(props, {emit}) {
    const internalValue = useVModel(props, 'modelValue', emit);

    const style = computed(() => {
      return ['blue', 'lighten-3'];
    });

    const queries: Ref<string[]> = computed(() =>
      props.assistedGradingPhrases
        ? props.assistedGradingPhrases.flatMap((x: AssistedGradingPhrases) => x.phrases)
        : []
    );

    return {
      internalValue,
      style,
      queries,
    };
  },
});
