





































































































































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AssistedGradingWritten from '@/common/components/AssistedGradingWritten.vue';
import Task from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import VueApexCharts from 'vue-apexcharts/dist/vue-apexcharts';
import {inject} from '@/container';
import {UseConcordiaExperiment1SlopeIntercept} from '@/tasks/composables/UseConcordiaExperiment1SlopeIntercept';

type Labels = {
  vialAndMass: string;
  vial: string;
  mass: string;
  moles: string;
  startingVolume: string;
  finalVolume: string;
  millilitersOfGasGenerated: string;
  litersOfGasGenerated: string;
};

type SlotNames = {
  headers: Record<`header.${keyof Labels | 'index'}`, string>;
  items: `item.${keyof Labels | 'index'}`[];
};

export default defineComponent({
  name: 'ConcordiaChem206Experiment1Part4',
  components: {StembleLatex, AssistedGradingWritten, ApexChart: VueApexCharts},
  mixins: [DynamicQuestionMixin()],
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DynamicQuestion>>,
      required: true,
    },
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const labels: Labels = {
      vialAndMass: 'Vial + Sample (mg)',
      vial: 'Vial (mg)',
      mass: 'Sample (mg)',
      moles: 'Moles of Sodium Bicarbonate (mol)',
      startingVolume: 'Starting Volume (mL)',
      finalVolume: 'Final Volume (mL)',
      millilitersOfGasGenerated: 'Carbon Dioxide Gas Generated (mL)',
      litersOfGasGenerated: 'Carbon Dioxide Gas Generated (L)',
    };

    const slotNames: SlotNames = {
      headers: {
        'header.index': '$\\text{Run}$',
        'header.vialAndMass': '$\\text{Vial} + \\ce{NaHCO3} \\text{(mg)}$',
        'header.vial': '$\\text{Emptied Vial (mg)}$',
        'header.mass': '$\\text{m}_\\ce{NaHCO3}\\text{ mg}$',
        'header.moles': '$\\text{n}_\\ce{NaHCO3}\\text{ mol}$',
        'header.startingVolume': '$\\text{V}_\\text{i}\\text{ mL}$',
        'header.finalVolume': '$\\text{V}_\\text{f}\\text{ mL}$',
        'header.millilitersOfGasGenerated': '$\\text{V}_\\ce{CO2}\\text{ mL}$',
        'header.litersOfGasGenerated': '$\\text{V}_\\ce{CO2}\\text{ L}$',
      },
      items: Object.keys(labels).map((label) => 'item.' + label) as SlotNames['items'],
    };

    const {inputs, colDefs, equation, apexSeries, apexOptions, addRun, removeRun} = inject(
      UseConcordiaExperiment1SlopeIntercept,
      labels,
      'moles',
      'litersOfGasGenerated',
      'rows',
      props.isMarking,
      '\\text{V}_\\ce{CO2}\\text{ L}',
      '\\text{n}_\\ce{NaHCO3}',
      0,
      0.02,
      0,
      0.3
    );
    inputs.value.experimentalMolarVolume = null;
    inputs.value.temperature = null;
    inputs.value.expectedMolarVolume = null;
    inputs.value.percentError = null;

    return {
      labels,
      slotNames,
      colDefs,
      equation,
      inputs,
      apexOptions,
      apexSeries,
      addRun,
      removeRun,
    };
  },
});
