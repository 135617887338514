/**
 * Calculate the maximum or minimum of a graph axis provided an extreme value
 *
 * FIXME: This still needs work.  Ideally this should give reasonable values for any provided value,
 * but may require both min and max extremes
 *
 * @param extremeValue that precedes the intended 'next' increment
 * @param maximum If the calculated value is intended to be the max or min of the axis
 * @param paddingScaleFactor How far past the extreme value to scale the axis
 */
export function calculateAxisMinMaxValue(
  extremeValue: number | undefined,
  maximum: boolean = true,
  paddingScaleFactor: number = 0.1
) {
  if (!extremeValue) {
    return maximum ? 10 : 0;
  }

  const absExtremeValue = Math.abs(extremeValue);

  // We calculate the magnitude of the number (i.e. decimal position) and then do one more decimal worth of precision
  const magnitude = (absExtremeValue > 0 ? Math.floor(Math.log10(absExtremeValue)) : 0) - 1;

  // Offset by a percentage of the extreme value
  const offset = (maximum ? 1 : -1) * absExtremeValue * paddingScaleFactor;

  // Normalize the value to the magnitude, round, then denormalize back to the original magnitude
  return Math.round((extremeValue + offset) * Math.pow(10, -magnitude)) / Math.pow(10, -magnitude);
}
